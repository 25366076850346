<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Формирование аналитической справки</span>
      <a class="close" @click="$emit('close')">
        <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              Название<h3>{{ config.content.title }}</h3>
            </v-col>

            <v-col cols="12">
              <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="selectedDate | dateFormat"
                      clearable
                      label="Дата, на которую формируется справка"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="menuDate = false; errorText = null"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  label="Регион"
                  v-model="selectedRegion"
                  :items="regions"
                  item-text="name"
                  return-object
                  @input="updateDepartments"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  label="ЦЗН"
                  v-model="selectedDepartment"
                  :items="departments"
                  item-text="name"
                  return-object
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions style="margin-left: 32%">
      <v-btn outlined @click="$emit('close')">
        отмена
      </v-btn>
      <v-btn color="primary" @click="submit" :disabled="loading">
        сформировать
      </v-btn>
    </v-card-actions>
    <div
        v-if="errorText != null"
        class="error"
        style="bottom: 0"
    >
      Ошибка: {{ errorText }}
    </div>
    <LoaderDialog :show="loading"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "../../elements/LoaderDialog";
import api from "@/modules/api";

export default {
  name: "AnalyticalReportConfigDialog",
  props: ['config'],
  components: { LoaderDialog },
  data: () => ({
    domain: 'analytical_report',
    format: 'docx',
    loading: false,
    errorText: null,
    menuDate: false,
    minDate: '2019-01-01',
    maxDate: '2030-12-31',
    regions: [],
    departments: [],
    selectedRegion: null,
    selectedDepartment: null,
    selectedDate: null,
  }),
  methods: {
    updateDepartments() {
      this.departments = this.selectedRegion.departments
      this.selectedDepartments = []
    },
    async downloadReport() {
      try {
        this.loading = true
        let params = {
          format: this.format,
          config_id: this.config.id,
          date: this.selectedDate,
        }
        if (this.selectedRegion) {
          params.region_code = this.selectedRegion.code
        }
        if (this.selectedDepartment) {
          params.czn_id = this.selectedDepartment.id
        }

        const queryString = new URLSearchParams()
        for (const key in params) {
          if (Array.isArray(params[key])) {
            params[key].forEach(value => queryString.append(key, value));
          } else {
            queryString.append(key, params[key]);
          }
        }

        const response = (await api.get(`/reports/generate/${this.domain}/1?${queryString}`)).payload
        this.downloadTaskId = response.task_id
        await this.checkTaskStatus()
      } catch (error) {
        console.error('Error generating report:', error)
        alert('Ошибка при генерации отчета!')
        this.loading = false
      }
    },
    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload
        if (response.status === 'SUCCESS') {
          await this.downloadFile()
        } else {
          setTimeout(this.checkTaskStatus, 2000)
        }
      } catch (error) {
        console.error('Error checking task status:', error)
        alert('Ошибка при проверке статуса задачи!')
        this.loading = false
      }
    },
    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload
        await this.$downloadBlob(blob, `${this.getReportTitle()}.${this.format}`)
      } catch (error) {
        console.error('Error downloading file:', error)
        alert('Ошибка при скачивании файла!')
      } finally {
        this.$emit('close')
        this.loading = false
      }
    },
    getReportTitle() {
      return this.config.content.title
    },
    validateSubmit() {
      if (this.selectedDate === null) {
        this.errorText = "Укажите дату";
        return false
      }
      return true
    },
    async submit() {
      if (this.validateSubmit()) {
        await this.downloadReport()
      }
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mDate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mDate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    this.regions = this.$cznDepartmentsByRegionList

    this.selectedDate = null
    this.selectedRegions = []
    this.selectedDepartments = []
  },
};
</script>
