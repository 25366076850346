<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <router-link to="/config" class="back-link router-link-active">
            <svg data-v-231527a2="" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path data-v-231527a2="" d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#0033A0">
              </path>
            </svg>
            назад
          </router-link>

          <h1>Подсистема «Формирование аналитических справок»</h1>

          <table class="config-list-table">
            <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Дата создания</th>
              <th>Шаблон</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="config in configs" :key="config.id">
              <td>{{ config.id }}</td>
              <td>{{ config.content.title }}</td>
              <td>{{ config.createdDate | dateFormat }}</td>
              <td>
                <a
                    v-if="config.content.attachment"
                    @click="downloadAttachment(config.content.attachment)"
                    download
                    class="file-link"
                >
                  <v-icon class="icon-item icon-file-document-outline"></v-icon>
                  {{ truncateStr(config.content.attachment.name, 50) }}
                </a>
              </td>
              <td style="width: 10px">
                <div class="edit" title="Выгрузка справки по шаблону">
                  <v-dialog
                      v-model="dialog"
                      max-width="600px"
                      persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click.prevent=""
                          class="icon-item icon-briefcase-eye-outline"
                      ></v-icon>
                    </template>
                    <AnalyticalReportConfigDialog
                        :config="config"
                        :key="dialogKey"
                        @close="dialog = false; dialogKey++"
                    />
                  </v-dialog>
                </div>
              </td>
              <td style="width: 10px">
                <div class="edit" title="Редактирование шаблона">
                  <router-link :to="'/config/analyticalReportConfigEdit/' + config.id">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </router-link>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="save-buttons">
            <v-btn color="primary" @click="$router.push('/config/analyticalReportConfigEdit/')">Создать новый шаблон</v-btn>
          </div>
        </div>
        <div v-if="errorText != null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {getDomainConfigs} from "@/modules/api.configs";
import {downloadFile} from "@/modules/api.files";
import AnalyticalReportConfigDialog from "@/components/config/new/AnalyticalReportConfigDialog.vue";

export default {
  name: "AnalyticalReportConfig",
  props: {},
  components: {LoaderDialog, AnalyticalReportConfigDialog},
  data() {
    return {
      configs: [],
      domain: 'analytical_report',
      apiLoaded: false,
      errorText: null,
      loading: false,
      dialog: false,
      dialogKey: 0,
    };
  },
  methods: {
    async loadConfigs() {
      this.loading = true
      let req = await getDomainConfigs(this.domain)
      if (req.ok) {
        this.configs = req.payload
        this.sortConfigsByCreatedDate()
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    },
    sortConfigsByCreatedDate() {
      this.configs.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)
    },
    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },
    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.loadConfigs()
  }
};
</script>
